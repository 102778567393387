<template>
  <div>
    <Echart :options="options" id="centreLeft1Chart" height="230px" width="516px"></Echart>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: 'axis',
            formatter: '{b0}({a0}): {c0}<br />{b1}({a1}): {c1}%'
          },
          legend: {
            data: newData.dimensions
          },
          xAxis: {
            data: newData.xData
          },
          yAxis: [
            {
              type: 'value',
              name: '单位/吨',
              show: true,
              interval: 100,
              axisLine: {
                show: false
              },
              splitLine: {    // gird 区域中的分割线
                show: true,   // 是否显示
                lineStyle: {
                  color: 'rgba(143, 171, 191, 1)',
                  width: 0.7,
                  type: 'solid'   // dashed
                }
              },
              scale: true,
              max: 500,
              min: 0,
              splitNumber: 5,
              boundaryGap: [0.2, 0.2]
            },
            {
              type: 'value',
              name: '%',
              interval: 10,
              axisLabel: {
                formatter: '{value}'
              },
              axisLine: {
                show: false
              },
              max: 40,
              min: 0,
              splitNumber: 5,
              boundaryGap: [0.2, 0.2]
            }
          ],
          series: [
            {
              name: '产量',
              type: 'bar',
              barWidth: 10,
              data: newData.data1,
              color: {
                type: 'linear',
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(122, 163, 204, 1)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(96, 112, 128, 0.1)' // 100% 处的颜色
                  }
                ]
              }
            },
            {
              name: '优品率',
              type: 'line',
              smooth: true,
              symbol: 'none', 
              data: newData.data2,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: '#00cdcb',
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(38, 191, 191, 0.5)'
                      },
                      {
                        offset: 1,
                        color: 'rgba(31, 89, 89, 0)'
                      }
                    ])
                  },
                }
              }
            },
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>