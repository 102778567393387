<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">作物周期及环境数据监测</span>
    </div>
    <div class="quantity">
      <!-- <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon1.png" alt="" class="quantity-img"> -->
      <div class="d-pro1"></div>
      <div class="item-box">
        <div class="item" v-for="(item, index) in data" :key="index">
          <img :src="item.url" alt="" class="item-icon">
          <span class="item-text">{{ item.text }}</span>
          <span class="item-num">{{ item.num }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon2.png',
          text: '空气温度',
          num: '27.10 °C'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon3.png',
          text: '空气湿度',
          num: '55.03 %RH'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon4.png',
          text: '光照',
          num: '1548 Lux'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon5.png',
          text: '二氧化碳',
          num: '550 ppm'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon2.png',
          text: '土壤温度',
          num: '28.4 °C'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon3.png',
          text: '土壤湿度',
          num: '59.10 %RH'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon6.png',
          text: '土壤氮',
          num: '83 mg/kg'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon7.png',
          text: '土壤麟',
          num: '118 mg/kg'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon8.png',
          text: '土壤EC值',
          num: '1092 us/cm'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon9.png',
          text: '土壤PH值',
          num: '7.99'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon10.png',
          text: '土壤钾',
          num: '220 mg/kg'
        },
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  margin-bottom: 10px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .quantity {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .d-pro1 {
      width: 193px;
      height: 274px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/6.png");
      -webkit-animation: d-pro1 8s infinite linear;
      -moz-animation: d-pro1 8s infinite linear;
      -o-animation: d-pro1 8s infinite linear;
      -ms-animation: d-pro1 8s infinite linear;
      animation: d-pro1 8s infinite linear;
      animation-fill-mode: backwards;
    }

    @keyframes d-pro1 {
      0% {
        background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/2.png");
      }

      30% {
        background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/3.png");
      }

      60% {
        background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/4.png");
      }

      80% {
        background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/5.png");
      }

      100% {
        background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/6.png");
      }
    }


    .item-box {
      .item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.86px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;

        .item-icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        .item-text {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
